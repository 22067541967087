<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <div class="row">
            <div class="col-md-12">
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.start_date"
                    :disabled-date="disabledStartDate"
                    placeholder="Mulai Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <ADatePicker
                    class="mr-2"
                    v-model:value="state.params.end_date"
                    :disabled-date="disabledEndDate"
                    placeholder="Akhir Tanggal"
                    allow-clear
                    style="width:300px;"
                    format="DD MMMM YYYY"/>
                <FilterRegional
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.region"/>
                <FilterArea
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.area"/>
            </div>
            <div class="col-md-12 mt-2">
                <FilterDistributor
                    class="mr-2"
                    v-can:hide.distributor
                    v-model:value="state.params.distributor"
                    v-model:region="state.params.region"
                    v-model:area="state.params.area"
                    v-model:kabupaten="state.params.kabupaten"/>
                <FilterToko
                    class="mr-2"
                    v-model:value="state.params.toko"/>
                <AButton
                    class="mr-2"
                    type="primary"
                    title="cari"
                    @click="btnFetchData()"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-lg-6 col-md-12 mt-auto">
            </div>
            <div class="col-lg-6 col-md-12 text-right mt-auto">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="queryParams()"
                    namefile="Laporan-Pengiriman-Forca-Pos"
                    @errors="errorMessage" />
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">
            </MdTable>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import apiClient from '@/services/axios'
import FilterDistributor from '@/components/filter/FilterDistributor'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import moment from 'moment'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/globalmixin'

export default defineComponent({
    components: {
        FilterDistributor,
        FilterRegional,
        FilterArea,
        FilterToko,
        DownloadExcel,
    },
    setup() {
        const errorMessage = ref()

        const state = reactive({
            columns: [
                {
                    title: 'No Transaksi',
                    dataIndex: 'salesNumber',
                },
                {
                    title: 'No Pengiriman',
                    dataIndex: 'deliveryNumber',
                },
                {
                    title: 'Kode Pelanggan',
                    dataIndex: 'customerCode',
                },
                {
                    title: 'Kode Toko SAP',
                    dataIndex: 'sapCustomerCode',
                },
                {
                    title: 'Tipe Toko',
                    dataIndex: 'customerType',
                },
                {
                    title: 'Nama Toko SAP',
                    dataIndex: 'customerName',
                },
                {
                    title: 'Area Toko',
                    dataIndex: 'area',
                },
                {
                    title: 'Region Toko',
                    dataIndex: 'region',
                },
                {
                    title: 'Alamat Toko SAP',
                    dataIndex: 'address',
                },
                {
                    title: 'Kecamatan',
                    dataIndex: 'district',
                },
                {
                    title: 'Nama Distrik',
                    dataIndex: 'city',
                },
                {
                    title: 'Provinsi',
                    dataIndex: 'province',
                },
                {
                    title: 'Kode Gudang',
                    dataIndex: 'warehouseCode',
                },
                {
                    title: 'Nama Gudang',
                    dataIndex: 'warehouseName',
                },
                {
                    title: 'Kode Tujuan',
                    dataIndex: 'destinationCode',
                },
                {
                    title: 'Nama Tujuan',
                    dataIndex: 'destinationName',
                },
                {
                    title: 'Kode Distributor',
                    dataIndex: 'distributorCode',
                },
                {
                    title: 'Nama Distributor',
                    dataIndex: 'distributorName',
                },
                {
                    title: 'Tanggal Transaksi',
                    dataIndex: 'salesDate',
                },
                {
                    title: 'Tanggal Pengiriman',
                    dataIndex: 'deliveryDate',
                },
                {
                    title: 'Nomor POL',
                    dataIndex: 'policeNumber',
                },
                {
                    title: 'Nomor Transaksi DTL',
                    dataIndex: 'deliveryNumber',
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'productCode',
                },
                {
                    title: 'Nama Material',
                    dataIndex: 'productName',
                },
                {
                    title: 'QTY',
                    dataIndex: 'qty',
                },
                {
                    title: 'Harga',
                    dataIndex: 'price',
                },
                {
                    title: 'Satuan',
                    dataIndex: 'uom',
                },
                {
                    title: 'OPCU',
                    dataIndex: 'brand',
                },
                {
                    title: 'Status',
                    dataIndex: 'deliveryStatus',
                },
                {
                    title: 'Tonase',
                    dataIndex: 'tonase',
                },
                {
                    title: 'Akses Toko Mark',
                    dataIndex: 'isAksesToko',
                },
                {
                    title: 'Delete Mark',
                    dataIndex: 'isDeleted',
                },
                {
                    title: 'Created At',
                    dataIndex: 'createdAt',
                },
                {
                    title: 'Updated At',
                    dataIndex: 'updatedAt',
                },
            ],
            endpoint: '/api/report/pengiriman-forca-pos',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                region: [],
                distributor: [],
                area: [],
                kabupaten: [],
                toko: [],
                start_date: moment(new Date()).startOf('month'),
                end_date: moment(new Date()).subtract(0, 'days'),
                page: 1,
                "per-page": 10,
            }),
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize
            
            btnFetchData()
        }

        const disabledStartDate = (current) => {
            return current && current > moment(state.params.end_date)
        }

        const disabledEndDate = (current) => {
            return current && moment(state.params.start_date) >= current
        }

        const queryParams = () => {
            let params = state.params

            if (state.params.start_date) {
                state.params.start_date = moment(state.params.start_date).format('YYYY-MM-DD')
            }

            if (state.params.end_date) {
                state.params.end_date = moment(state.params.end_date).format('YYYY-MM-DD')
            }

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnFetchData = () => {
            fetchDataList()
        }

        // handle vue
        onMounted(() => {
            // btnFetchData()
        })

        return {
            btnFetchData,
            state,
            handleTableChange,
            errorMessage,
            disabledStartDate,
            disabledEndDate,
            queryParams,
        }
    },
})
</script>

<style lang="scss" scoped>
.card {
    height: 100%;
    .card-body {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
}
</style>